import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../HeaderSection/Header";
import Breadcrumb from "../Blogs/Breadcrumb";
import FooterSection from "../FooterSection/Footer";
import {
  getUserDetails,
  requestCardList,
  requestPurchaseSubscription,
  subscriptionPlanRequest,
} from "../../redux/actions";
import { getValidationErrors } from "../../helpers";
import { CardSchema } from "../../validators";
import { monthOptions, yearOptions } from "../../common";
import Loader from "../../common/loader";

const Thankyou = (props) => {
  return (
    <div className="thankyou-page">
      {/*====== Scroll To Top Area Start ======*/}
      <div id="scrollUp" title="Scroll To Top">
        <i className="fas fa-arrow-up" />
      </div>
      {/*====== Scroll To Top Area End ======*/}
      <div className="main">
        <Header imageData={"/img/logo-white.png"} />
        <Breadcrumb title="Checkout" />
        <section id="contact" className="contact-area ptb_100">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 col-lg-6">
                {/* Section Heading */}
                <div className="section-heading">
                  <h2 className="text-capitalize">Thank You for Purchasing!</h2>
                  <p className=" mt-4">
                    Your order ID is <span className="theme-color">{}</span>.
                    You will receive an email confirmation shortly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <FooterSection />
      </div>
    </div>
  );
};

export default Thankyou;
