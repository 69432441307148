import { createAction } from "redux-actions";

export const SubscriptionActions = {
  REQUEST_SUBSCRIPTION_LIST: "Request to fetch Subscription list",
  SUBSCRIPTION_LIST_SUCCESS: "Subscription list fetched successfully",
  SUBSCRIPTION_LIST_FAILURE: "Failed to fetched Subscription list",

  SUBSCRIPTION_PLAN_REQUEST: "Request to fetch Subscription plan",
  SUBSCRIPTION_PLAN_SUCCESS: "Subscription plan fetched successfully",
  SUBSCRIPTION_PLAN_FAILED: "Failed to fetched Subscription plan",

  REQUEST_CARD_LIST: "Request to fetch card list details",
  CARD_LIST_SUCCESS: "Card list details fetched successfully",
  CARD_LIST_FAILED: "Failed to fetched card list details",

  REQUEST_PURCHASE_SUBSCRIPTION: "Request to purchase subscription",
  PURCHASE_SUBSCRIPTION_SUCCESS: "Subscription purchased successfully",
  PURCHASE_SUBSCRIPTION_FAILURE: "Failed to purchased subscription",

  CHECKOUT_REDIRECT: "Redirect checkout data",
};

// List Subscription actions
export const requestSubscriptionList = createAction(
  SubscriptionActions.REQUEST_SUBSCRIPTION_LIST
);
export const subscriptionListSuccess = createAction(
  SubscriptionActions.SUBSCRIPTION_LIST_SUCCESS
);
export const subscriptionListFailed = createAction(
  SubscriptionActions.SUBSCRIPTION_LIST_FAILURE
);

// View Subscription actions
export const subscriptionPlanRequest = createAction(
  SubscriptionActions.SUBSCRIPTION_PLAN_REQUEST
);

export const subscriptionPlanSuccess = createAction(
  SubscriptionActions.SUBSCRIPTION_PLAN_SUCCESS
);

export const subscriptionPlanFailed = createAction(
  SubscriptionActions.SUBSCRIPTION_PLAN_FAILED
);

// List card actions
export const requestCardList = createAction(
  SubscriptionActions.REQUEST_CARD_LIST
);
export const cardListSuccess = createAction(
  SubscriptionActions.CARD_LIST_SUCCESS
);
export const cardListFailed = createAction(
  SubscriptionActions.CARD_LIST_FAILED
);

// Purchase Subscription actions
export const requestPurchaseSubscription = createAction(
  SubscriptionActions.REQUEST_PURCHASE_SUBSCRIPTION
);
export const purchaseSubscriptionSuccess = createAction(
  SubscriptionActions.PURCHASE_SUBSCRIPTION_SUCCESS
);
export const PurchaseSubscriptionFailed = createAction(
  SubscriptionActions.PURCHASE_SUBSCRIPTION_FAILURE
);

export const checkoutRedirect = createAction(
  SubscriptionActions.CHECKOUT_REDIRECT
);
