import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { requestSubscriptionList } from "../../redux/actions";

const PricingSection = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState("monthly");
  const [subscriptionData, setSubscriptionData] = useState([]);
  const { subscriptionList = {} } = useSelector(
    (state) => state.subscriptionReducer
  );

  const trialPlan = {
    _id: '',
    subscriptionName: "Free Trial",
    subscriptionAmount: 0,
    numberOfFollwedOpportunity: 10,
    numberOfFollwedVendors: 10,
    numberOfReports: 10,
    numberOfExports: 10,
    numberOfUsers: 1,
    stripePlanId: 'free',
  }

  useEffect(() => {
    dispatch(requestSubscriptionList());
  }, []);

  useEffect(() => {
    if (subscriptionList && subscriptionList.months) {
      setSubscriptionData([trialPlan, ...subscriptionList.months]);
    }
  }, [subscriptionList]);

  const onHandleTab = (tab) => {
    setActiveTab(tab);
    if (tab === "monthly") {
      setSubscriptionData([trialPlan, ...subscriptionList.months]);
    } else {
      setSubscriptionData([trialPlan, ...subscriptionList.years]);
    }
  };

  const buyNow = (planIds) => {
    console.log("props.history", history, planIds);
    if (planIds)
      history.push(`/checkout/${planIds}`);
    else
      window.location.href = `${process.env.REACT_APP_FRONTEND_ENDPOINT}/register`;
  };

  return (
    <section
      id="pricing"
      className="section price-plan-area bg-gray overflow-hidden ptb_100"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-7">
            {/* Section Heading */}
            <div className="section-heading text-center">
              <h2>{"GovFetch Plans & Pricing"}</h2>
              <p className="d-none d-sm-block mt-4">
                Find the plan thats right for you.
              </p>
              <p className="d-block d-sm-none mt-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Laborum obcaecati.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="text-center">
              <ul className="plan-tabs">
                <li className="nav-item ">
                  <span
                    className={`nav-link ${activeTab === "monthly" ? "active" : ""
                      }`}
                    onClick={() => onHandleTab("monthly")}
                  >
                    Monthly
                  </span>
                </li>
                <li className="nav-item ">
                  <span
                    className={`nav-link ${activeTab === "yearly" ? "active" : ""
                      }`}
                    onClick={() => onHandleTab("yearly")}
                  >
                    Yearly
                  </span>
                </li>
              </ul>
            </div>
            <div className="row price-plan-wrapper justify-content-center">
              {subscriptionData.length ? (
                subscriptionData.map((item, id) => {
                  return item.stripePlanId ? (
                    <div key={`p_${id}`} className="col-12 col-md-6 col-lg-3 my-4">
                      {/* Single Price Plan */}
                      <div className="single-price-plan text-center p-5">
                        {/* Plan Thumb */}
                        <div className="plan-thumb">
                          <img
                            className="avatar-lg"
                            src="/img/price_basic.png"
                            alt=""
                          />
                        </div>
                        {/* Plan Title */}
                        <div className="plan-title my-2 my-sm-3">
                          <h3 className="text-uppercase">
                            {item.subscriptionName}
                          </h3>
                        </div>
                        {/* Plan Price */}
                        <div className="plan-price pb-2 pb-sm-3">
                          <h1 className="color-primary">
                            <small className="fw-7">$</small>
                            {item.subscriptionAmount}
                          </h1>
                          <div className="text-secondary font-14">
                            {activeTab === "yearly" ? "Yearly" : "Monthly"}
                          </div>
                        </div>
                        {/* Plan Description */}
                        <div className="plan-description">
                          <ul className="plan-features">
                            <li className="border-top py-2">
                              unlimited view of opportuntiees
                            </li>
                            <li className="border-top py-2">
                              {item.numberOfFollwedOpportunity} followed
                              opportunity
                            </li>
                            <li className="border-top py-2">
                              {item.numberOfFollwedVendors} followed vendor
                            </li>
                            {/* <li className="border-top py-2">
                                  1 opportunity alert
                                </li> */}
                            <li className="border-top py-2">
                              {" "}
                              {item.numberOfReports} reports
                            </li>
                            <li className="border-top py-2">
                              {" "}
                              {item.numberOfExports} exports
                            </li>
                            <li className="border-top py-2">
                              {item.numberOfUsers} user
                            </li>

                            {/* <li className="border-top border-bottom py-2">
                                  Free 15 day trail***
                                </li> */}
                          </ul>
                        </div>
                        {/* Plan Button */}
                        <div className="plan-button">
                          <span
                            onClick={() => buyNow(item._id)}
                            className="btn"
                          >
                            {id ? 'Buy Now' : 'Try For Free'}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })
              ) : (
                <div className="no-data text-center">
                  <div className="no-data-content">
                    <h5>No SubscriptionPlan Found</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row justify-content-center pt-5">
          <p className="text-body pt-4 fw-5">
            Not sure what to choose? <a href="/#">Contact Us</a>
          </p>
        </div>

        <div class="enterprise-block d-flex justify-content-center">
          <div class="enterprise-content">
            <h3>Enterprise</h3>
            <p>Get in touch with us to talk about your custom GovFetch Enterprise plan.</p>
            <div class="enterprise-detail">
              <p className="font-weight-bold">Shane Prosser</p>
              <p>Founder</p>
              <p><b>Phone:</b> 1211112121</p>
              <p><b>Email</b> info@govfetch.com</p>
            </div>
          </div>
          <div class="enterprise-btn">
            <a href="/#" class="btn btn-bordered mt-4">Get in touch</a>
          </div>
        </div>

      </div>
    </section>
  );
};

export default PricingSection;
