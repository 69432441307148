import React, { Component } from 'react';

const initData = {
    heading: "Try GovFetch Free Today",
    text: "Sign up now and start winning more contracts. No mandatory demos or yearly contracts.",
    submitText: "Subscribe"
}

class Subscribe extends Component {
    state = {
        initData: {}
    }
    componentDidMount() {
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <section className="section subscribe-area section download-area overlay-dark ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            <div className="subscribe-content">
                                <h2 className="text-center text-white">{this.state.initData.heading}</h2>
                                <p className="my-4 text-white">{this.state.initData.text}</p>
                                {/* Subscribe Form */}
                                <form className="subscribe-form">
                                    <div className="form-group">
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your email" />
                                    </div>
                                    <button type="submit" className="btn btn-lg btn-block">{this.state.initData.submitText}</button>

                                    {/* <div className="subscribe-form d-flex align-items-center">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="info@yourmail.com"
                                        />
                                        <button type="submit" className="btn btn-rounded btn-bordered">
                                            {this.state.initData.submitText}
                                        </button>
                                    </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Subscribe;