import { createAction } from "redux-actions";

export const GlobalSettingActions = {
  REQUEST_GLOBALSETTING_LIST: "Request to fetch global setting list",
  GLOBALSETTING_LIST_SUCCESS: "Global setting list fetched successfully",
  GLOBALSETTING_LIST_FAILURE: "Failed to fetched global setting list",
};

// List global setting actions
export const requestGlobalSettingList = createAction(
  GlobalSettingActions.REQUEST_GLOBALSETTING_LIST
);
export const globalSettingListSuccess = createAction(
  GlobalSettingActions.GLOBALSETTING_LIST_SUCCESS
);
export const globalSettingListFailed = createAction(
  GlobalSettingActions.GLOBALSETTING_LIST_FAILURE
);

