import React, { Component } from "react";

import Header from "../components/HeaderSection/Header";
import HeroSection from "../components/HeroSection/HeroFive";
import FeatureSection from "../components/Features/FeatureFive";
import ServiceSection from "../components/ServiceSection/ServiceFive";
import DiscoverSection from "../components/DiscoverSection/DiscoverFive";
import Work from "../components/WorkSection/Work";
import ScreenshotSection from "../components/ScreenshotSection/ScreenshotsTwo";
import ReviewSection from "../components/ReviewSection/ReviewOne";
import PricingSection from "../components/PricingSection/PricingOne";
import FaqSection from "../components/FaqSection/FaqOne";
import Download from "../components/DownloadSection/Download";
import Subscribe from "../components/SubscribeSection/Subscribe";
import Team from "../components/TeamSection/Team";
import ContactSection from "../components/ContactSection/Contact";
import FooterSection from "../components/FooterSection/Footer";

class ThemeFive extends Component {
  render() {
    console.log("this.props.history", this.props.history);
    return (
      <div className="homepage-5">
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header imageData={"/img/logo-white.png"} {...this.props} />
          <HeroSection {...this.props} />
          <ReviewSection {...this.props} />
          <FeatureSection {...this.props} />
          <DiscoverSection {...this.props} />
          <ServiceSection {...this.props} />
          <Work {...this.props} />
          <ScreenshotSection {...this.props} />
          <PricingSection {...this.props} />
          <FaqSection {...this.props} />
          {/* <Team {...this.props} /> */}
          {/* <Download {...this.props} /> */}
          <Subscribe {...this.props} />
          {/* <ContactSection {...this.props} /> */}
          <FooterSection {...this.props} />
        </div>
      </div>
    );
  }
}

export default ThemeFive;
