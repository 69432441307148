import React, { Component } from "react";
import Header from "../HeaderSection/Header";
import Breadcrumb from "../Blogs/Breadcrumb";
import PricingSection from "../PricingSection/PricingOne";
import FooterSection from "../FooterSection/Footer";

class Pricing extends Component {
  state = {};

  render() {
    return (
      <div>
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header imageData={"/img/logo-white.png"} {...this.props} />
          <Breadcrumb title="Pricing" />
          <PricingSection />

          <FooterSection />
        </div>
      </div>
    );
  }
}

export default Pricing;
