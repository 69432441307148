import moment from "moment";
/*
------------------
  Get Time Stamp
------------------
*/

const getDate = (data) => {
  return moment(data).format("MMM DD YYYY");
};

const setLabelValue = (value, fieldOptions) => {
  if (value !== null) {
    // eslint-disable-next-line
    return fieldOptions.filter((item) => item.value === value)[0];
  } else {
    return null;
  }
};

const countryCodeOptions = [
  { value: "+1", label: "+1" },
  { value: "+90", label: "+90" },
  { value: "+91", label: "+91" },
  { value: "+92", label: "+92" },
];

const monthOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

const yearOptions = [
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
  { value: "2028", label: "2028" },
  { value: "2029", label: "2029" },
  { value: "2030", label: "2030" },
  { value: "2031", label: "2031" },
  { value: "2032", label: "2032" },
  { value: "2033", label: "2033" },
  { value: "2034", label: "2034" },
  { value: "2035", label: "2035" },
  { value: "2036", label: "2036" },
  { value: "2037", label: "2037" },
  { value: "2038", label: "2038" },
  { value: "2039", label: "2039" },
  { value: "2040", label: "2040" },
  { value: "2041", label: "2041" },
];

const passwordSpaceRegex = /^\S*$/;

const alphaNumeric = /^[A-Za-z0-9 ]+$/;

const defaultDateTimeFormat = "LLL";
const defaultDateFormat = "MM/DD/YYYY";
const dbAcceptableFormat = "YYYY-MM-DD";

const alphabeticCharacterRegex = /^[a-zA-Z() ]+$/;

const alphaNumericCharacterRegex = /^[a-zA-Z0-9() ]+$/;

const numericCharacterRegex = /^[0-9() ]+$/;

const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{6,20}$/;
/** Password regex combination
 * one digit must
 * one character must (lower or upper)
 * every other things optional
 **/

const FieldsMaxLengths = {
  firstName: 20,
  lastName: 20,
  mobileNumber: 15,
  fax: 16,
  cardName: 40,
  cardNumber: 16,
  cvv: 4,
  expMonth: 2,
  expYear: 4,
};

const FieldsMinLengths = {
  firstName: 3,
  lastName: 3,
  mobileNumber: 9,
  fax: 9,
  cardName: 3,
  cardNumber: 16,
  cvv: 3,
  expMonth: 1,
  expYear: 4,
};

const facebookUrl =
  // eslint-disable-next-line
  /(?:http:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/;
const twitterUrl = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
const instaUrl =
  // eslint-disable-next-line
  /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im;
const linkedInURL =
  // eslint-disable-next-line
  /(http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

const acceptedVideoFormat =
  "video/lv, video/mp4, video/3gp, video/quicktime, video/x-msvideo, video/x-ms-wmv";
const reservedDomain = ["admin", "api"];

const acceptedImageFormat = [
  "image/jpg",
  "image/png",
  "image/jpeg",
  "image/tiff",
  "image/svg+xml",
  "image/gif",
  "image/x-png",
];

const acceptedImportFileFormat = ".csv, .xls, .xlsx";

export {
  setLabelValue,
  alphaNumeric,
  countryCodeOptions,
  defaultDateTimeFormat,
  defaultDateFormat,
  dbAcceptableFormat,
  alphabeticCharacterRegex,
  alphaNumericCharacterRegex,
  numericCharacterRegex,
  reservedDomain,
  FieldsMaxLengths,
  FieldsMinLengths,
  passwordRegex,
  facebookUrl,
  instaUrl,
  linkedInURL,
  twitterUrl,
  acceptedImageFormat,
  acceptedVideoFormat,
  acceptedImportFileFormat,
  passwordSpaceRegex,
  getDate,
  monthOptions,
  yearOptions,
};
