const ApiRoutes = {
  GET_SUBSCRIPTION: {
    service: "/subscription-plan",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_SUBSCRIPTION_PLAN: {
    service: "/subscription-plan",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  GET_CARDS: {
    service: "/card",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  CHECKOUT: {
    service: "/subscription-plan",
    url: "/checkout",
    method: "POST",
    authenticate: true,
  },
  GET_PROFILE: {
    service: "/auth",
    url: "/me",
    method: "GET",
    authenticate: true,
  },
  GET_GLOBALSETTING: {
    service: "/social-media",
    url: "/",
    method: "GET",
    authenticate: true,
  },
};

export default ApiRoutes;
