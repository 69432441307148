/** @format */

import { handleActions } from "redux-actions";
import { GlobalSettingActions } from "../actions";
import { GlobalSettingInitialStates } from "../states";

export const GlobalSettingReducer = handleActions(
  {
    [GlobalSettingActions.REQUEST_GLOBALSETTING_LIST]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [GlobalSettingActions.GLOBALSETTING_LIST_SUCCESS]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      globalSettingList: action.payload.globalSettingList,
    }),
    [GlobalSettingActions.GLOBALSETTING_LIST_FAILURE]: (
      state = GlobalSettingInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  GlobalSettingInitialStates
);
