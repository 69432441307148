import React, { Component } from "react";

const data = {
  heading: "Frequently asked questions",
  headingTwo: "Have questions? Look here",
  headingText: "",
  headingTexttwo:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
  faqText: "Haven't find suitable answer?",
  faqTextLink: "Tell us what you need.",
};
const faqData = [
  {
    id: 1,
    title: "Do you have annual plans?",
    content:
      "Yes, and we offer a 25% discount for paying annually. The prices shown on the “yearly” tab on the pricing page reflect the annual discount.",
  },
  {
    id: 2,
    title: "Is there a contract?",
    content:
      "Our monthly plans have no contract or long term commitment. Our enterprise plans are annual.",
  },
  {
    id: 3,
    title: "Can I upgrade or downgrade my plan later?",
    content:
      "Yes, once logged in under “subscription” you can choose to upgrade or downgrade your plan at any time.",
  },
];
const faqDataTwo = [
  {
    id: 1,
    title: "Can I download data or reports into Excel?",
    content:
      "Yes, you can easily download reports into an Excel.csv file.",
  },
  {
    id: 2,
    title: "Export limits clarification question?**(below is GovTribe’s)",
    content:
      "The 50, 3000, and 25k export limits for the Solo, Standard, and Premium plans respectively refer to the number of records per individual export. You can have as many exports as you want, as long as the total number of records within each export is less than your Export Limit (i.e. 50, 3k, 25k).",
  },
];
const faqDataThree = [
  {
    id: 1,
    title: "How to install sApp?",
    content:
      "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
  },
  {
    id: 2,
    title: "Can I get support from the Author?",
    content:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
  },
  {
    id: 3,
    title: "Do you have a free trail?",
    content:
      "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    id: 4,
    title: "How can I edit my personal information?",
    content:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
  },
  {
    id: 5,
    title: "Contact form isn't working?",
    content:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
  },
];

class FaqSection extends Component {
  state = {
    data: {},
    faqData: [],
    faqDataTwo: [],
    faqDataThree: [],
  };
  componentDidMount() {
    this.setState({
      data: data,
      faqData: faqData,
      faqDataTwo: faqDataTwo,
      faqDataThree: faqDataThree,
    });
  }
  render() {
    return (
      <section className="section faq-area style-two ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-7">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">{this.state.data.heading}</h2>
                <p className="d-none d-sm-block mt-4">
                  {this.state.data.headingText}
                </p>
                <p className="d-block d-sm-none mt-4">
                  {this.state.data.headingTexttwo}
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              {/* FAQ Content */}
              <div className="faq-content">
                {/* GovFetch Accordion */}
                <div className="accordion" id="sApp-accordion">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                      {/* Single Card */}
                      {this.state.faqData.map((item, idx) => {
                        return (
                          <div key={`fo_${idx}`} className="card border-0">
                            {/* Card Header */}
                            <div className="card-header bg-inherit border-0 p-0">
                              <h2 className="mb-0">
                                <button className="btn px-0 py-2" type="button">
                                  {item.title}
                                </button>
                              </h2>
                            </div>
                            {/* Card Body */}
                            <div className="card-body px-0 py-3">
                              {item.content}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-12 col-md-6">
                      {/* Single Card */}
                      {this.state.faqDataTwo.map((item, idx) => {
                        return (
                          <div key={`ft_${idx}`} className="card border-0">
                            {/* Card Header */}
                            <div className="card-header bg-inherit border-0 p-0">
                              <h2 className="mb-0">
                                <button className="btn px-0 py-2" type="button">
                                  {item.title}
                                </button>
                              </h2>
                            </div>
                            {/* Card Body */}
                            <div className="card-body px-0 py-3">
                              {item.content}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <p className="text-body text-center pt-4 fw-5">
                      {this.state.data.faqText}{" "}
                      <a href="/#">{this.state.data.faqTextLink}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FaqSection;
