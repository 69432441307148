import React from "react";
import { useSelector } from "react-redux";
import Header from "../HeaderSection/Header";

const ThankYou = (props) => {
  const { checkoutData } = useSelector((state) => state.subscriptionReducer);
  console.log("propspropspropsprops", props.history);
  console.log("checkoutData", checkoutData);
  return (
    <div className="inner inner-pages">
      <div className="main">
        <Header imageData={"/img/logo-white.png"} />
        <section
          id="home"
          className="section welcome-area inner-area bg-overlay h-100vh overflow-hidden"
        >
          <div className="container h-100">
            <div className="row align-items-center h-100">
              <div className="col-12 col-md-12">
                <div className="welcome-intro text-center">
                  <div className="text-center mb-4">
                    <img src={"/img/check-mark-white.png"} width="100px" />
                  </div>

                  <h1 className="text-white">Thank You for Purchasing</h1>
                  <h5 className="text-white fw-4 mt-3 mb-4">
                    Your Payment was successfull! <br />
                    Your order ID is{" "}
                    <span className="fw-5 ">
                      {checkoutData.transactionId}
                    </span>{" "}
                    . You will receive an email confirmation shortly.
                  </h5>

                  <a href="/" className="btn sApp-btn text-uppercase">
                    Back to Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ThankYou;
