import React, { Component } from "react";
import { connect } from "react-redux";
import { getToken, removeToken } from "../../common/Token";
import { getUserDetails } from "../../redux/actions";

class Header extends Component {
  componentDidMount() {
    const data = getToken();
    console.log("tokendata", data);
    if (data) {
      this.props.getUserDetails();
    }
  }

  handleLogout = () => {
    removeToken();
    this.props.history.push("/");
    window.location.reload();
  };

  render() {
    const {
      authReducer: { loggedIn, user = {} },
    } = this.props;

    return (
      <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
        <div className="container position-relative">
          <div className="logo-block">
            <a className="navbar-brand" href="/">
              <img
                className="navbar-brand-regular"
                src="/img/logo/mono-logo-light.svg"
                alt="brand-logo"
                width="200px"
              />
              <img
                className="navbar-brand-sticky"
                src="/img/logo/logo-dark.svg"
                alt="sticky brand-logo"
                width="200px"
              />
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <div class="collapse navbar-collapse justify-content-between customize-collapse" id="navbarSupportedContent">
            <nav>
              <ul className="navbar-nav align-items-center" id="navbar-nav">
                {/* <li className="nav-item">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link scroll" href="#reviews">
                    Reviews
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="#features">
                    Features
                  </a>
                </li>
                <li className="nav-item dropdown text-center">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Explore
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <h6 class="dropdown-header">Opportunities</h6>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/opportunity`}
                      >
                        Federal Contract Opportunities
                      </a>
                    </li>
                    <li>
                      <h6 class="dropdown-header">Awards</h6>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/awards`}
                      >
                        Federal Contract Awards
                      </a>
                    </li>
                    <li>
                      <h6 class="dropdown-header">Participants</h6>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/federalAgencies`}
                      >
                        Federal Agencies
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/federalPeople`}
                      >
                        Government Contacts
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/vendor`}
                      >
                        Vendor
                      </a>
                    </li>
                    <li>
                      <h6 class="dropdown-header">Categories</h6>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/naics`}
                      >
                        NAICS Category
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/psc`}
                      >
                        PSC Category
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link scroll" href="#screenshots">
                    Screenshots
                  </a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link scroll" href="#pricing">
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="#about">
                    About Us
                  </a>
                </li>
              </ul>
            </nav>
            <ul className="navbar-nav align-items-center" id="navbar-nav">
              {getToken() ? (
                <>
                  <li className="nav-item dropdown user-dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/#"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      caret={false}
                    >
                      <div className="d-flex align-items-center">
                        <span className="text-capitalize mr-2">
                          {user.firstName ? user.firstName : ""}{" "}{user.lastName ? user.lastName : ""}
                        </span>
                        <span class="user-icon">
                          <i className="fas fa-user" />
                        </span>
                        <span>
                          <i className="fas fa-angle-down ml-2" />
                        </span>
                      </div>
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/dashboard`}
                        >
                          <i className="fas fa-th-large mr-2" />
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/profile`}
                        >
                          <i className="fas fa-user mr-2" />
                          Profile
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/settings`}
                        >
                          <i className="fas fa-cog mr-2" />
                          Settings
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={this.handleLogout}
                          href="javascript:void(0)"
                        >
                          <i class="fas fa-sign-out-alt mr-2"></i>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/login`}
                    >
                      <i className="far fa-user mr-2"></i>
                      Login
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href={`${process.env.REACT_APP_FRONTEND_ENDPOINT}/register`}
                      className="btn sApp-btn mx-1"
                    >
                      Start Free Trial
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>



          {/* <button
            className="navbar-toggler d-lg-none"
            type="button"
            data-toggle="navbarToggler"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button> */}
          {/* <div className="navbar-inner"></div> */}
          {/*  Mobile Menu Toggler */}
          {/* <button
              className="navbar-toggler d-lg-none"
              type="button"
              data-toggle="navbarToggler"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button> */}

        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: () => dispatch(getUserDetails()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
