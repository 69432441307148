/** @format */
import * as Yup from "yup";
import {
  FieldsMinLengths,
  FieldsMaxLengths,
  message,
  alphabeticCharacterRegex,
} from "../common";

export const CardSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Card number"))
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Card number"),
      (value) => !value || (value && !isNaN(value))
    )
    .min(
      FieldsMinLengths.cardNumber,
      message.MinLengthError.replace(":item", "Card number").replace(
        ":length",
        JSON.stringify(FieldsMinLengths.cardNumber)
      )
    )
    .max(
      FieldsMaxLengths.cardNumber,
      message.MaxLengthError.replace(":item", "Card number").replace(
        ":length",
        JSON.stringify(FieldsMaxLengths.cardNumber)
      )
    ),

  cardName: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Card name"))
    .matches(
      alphabeticCharacterRegex,
      message.InvalidName.replace(":item", "Card Name")
    )
    .min(
      FieldsMinLengths.cardName,
      message.MinLengthError.replace(":item", "Card name").replace(
        ":length",
        JSON.stringify(FieldsMinLengths.cardName)
      )
    )
    .max(
      FieldsMaxLengths.cardName,
      message.MaxLengthError.replace(":item", "Card name").replace(
        ":length",
        JSON.stringify(FieldsMaxLengths.cardName)
      )
    ),
  expMonth: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Month"))
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Month"),
      (value) => !value || (value && !isNaN(value))
    )
    .min(
      FieldsMinLengths.expMonth,
      message.MinLengthError.replace(":item", "Month").replace(
        ":length",
        JSON.stringify(FieldsMinLengths.expMonth)
      )
    )
    .max(
      FieldsMaxLengths.expMonth,
      message.MaxLengthError.replace(":item", "Month").replace(
        ":length",
        JSON.stringify(FieldsMaxLengths.expMonth)
      )
    ),
  expYear: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "Year"))
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "Year"),
      (value) => !value || (value && !isNaN(value))
    )
    .min(
      FieldsMinLengths.expYear,
      message.MinLengthError.replace(":item", "Year").replace(
        ":length",
        JSON.stringify(FieldsMinLengths.expYear)
      )
    )
    .max(
      FieldsMaxLengths.expYear,
      message.MaxLengthError.replace(":item", "Year").replace(
        ":length",
        JSON.stringify(FieldsMaxLengths.expYear)
      )
    ),
  cvv: Yup.string()
    .trim()
    .required(message.Required.replace(":item", "CVV"))
    .test(
      "check-num",
      message.InvalidNumber.replace(":item", "CVV"),
      (value) => !value || (value && !isNaN(value))
    )
    .min(
      FieldsMinLengths.cvv,
      message.MinLengthError.replace(":item", "CVV").replace(
        ":length",
        JSON.stringify(FieldsMinLengths.cvv)
      )
    )
    .max(
      FieldsMaxLengths.cvv,
      message.MaxLengthError.replace(":item", "CVV").replace(
        ":length",
        JSON.stringify(FieldsMaxLengths.cvv)
      )
    ),
});
