import React from "react";
import "./index.css";

const Loader = ({ classname }) => {
  return (
    <div className={`main-loader-wrap position-relative ${classname}`}>
      <div className={"loader-block"}>
        <div className={`loader`}>
          <svg className={"circular-loader"} viewBox="25 25 50 50">
            <circle
              className={"loader-path"}
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="#fff"
              strokeWidth="2.5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Loader;
