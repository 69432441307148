import React from "react";
import { Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
import ThemeTwo from "../themes/theme-two";
import ThemeThree from "../themes/theme-three";
import ThemeFour from "../themes/theme-four";
import ThemeFive from "../themes/theme-five";
import ThemeSix from "../themes/theme-six";
import BlogTwoColumn from "../components/Blogs/BlogTwoColumn";
import BlogThreeColumn from "../components/Blogs/BlogThreeColumn";
import BlogLeftSidebar from "../components/Blogs/BlogLeftSidebar";
import BlogRightSidebar from "../components/Blogs/BlogRightSidebar";
import BlogDetailsLeftSidebar from "../components/Blogs/BlogDetailsLeftSidebar";
import BlogDetailsRightSidebar from "../components/Blogs/BlogDetailsRightSidebar";
import Reviews from "../components/ReviewSection/Reviews";
import Pricing from "../components/PricingSection/Pricing";
import DownloadPage from "../components/DownloadSection/DownloadPage";
import SubscribePage from "../components/SubscribeSection/SubscribePage";
import ThankYouNew from "../components/Thankyou";
import ThankYou from "../components/InnerSection/ThankYou";
import ComingSoon from "../components/InnerSection/ComingSoon";
import Login from "../components/Accounts/Login";
import Signup from "../components/Accounts/Signup";
import Forgot from "../components/Accounts/Forgot";
import Faq from "../components/FaqSection/Faq";
import ErrorPage from "../components/ErrorPage/404";
import ContactPage from "../components/ContactSection/ContactPage";
import CheckoutPage from "../components/Checkout";
import User from "../components/User";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router history={this.props.history}>
          <Switch>
            <Route
              exact
              path="/"
              // component={ThemeFive}
              render={(props) => <ThemeFive {...props} />}
            />
            <Route path="/theme-two" component={ThemeTwo} />
            <Route path="/theme-three" component={ThemeThree} />
            <Route path="/theme-four" component={ThemeFour} />
            <Route path="/theme-five" component={ThemeOne} />
            <Route path="/theme-six" component={ThemeSix} />
            <Route path="/blog-two-column" component={BlogTwoColumn} />
            <Route path="/blog-three-column" component={BlogThreeColumn} />
            <Route path="/blog-left-sidebar" component={BlogLeftSidebar} />
            <Route path="/blog-right-sidebar" component={BlogRightSidebar} />
            <Route
              path="/blog-details-left-sidebar"
              component={BlogDetailsLeftSidebar}
            />
            <Route
              path="/blog-details-right-sidebar"
              component={BlogDetailsRightSidebar}
            />
            <Route path="/reviews" component={Reviews} />
            <Route path="/pricing" render={(props) => <Pricing {...props} />} />
            <Route path="/download-page" component={DownloadPage} />
            <Route path="/subscribe-page" component={SubscribePage} />
            <Route
              path="/thank-you"
              render={(props) => <ThankYouNew {...props} />}
            />
            <Route
              path="/thankyou"
              render={(props) => <ThankYou {...props} />}
            />
            <Route path="/user" render={(props) => <User {...props} />} />
            <Route path="/coming-soon" component={ComingSoon} />
            {/* <Route path="/login" render={(props) => <Login {...props} />} /> */}
            {/* <Route path="/signup" component={Signup} /> */}
            {/* <Route path="/forgot" component={Forgot} /> */}
            <Route path="/faq" component={Faq} />
            <Route path="/contact-page" component={ContactPage} />
            <Route path="/checkout/:id" component={CheckoutPage} />

            <Route path="*" component={ErrorPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;
