/** @format */

import { handleActions } from "redux-actions";
import { AuthActions } from "../actions";
import { AuthInitialStates } from "../states";

export const AuthReducer = handleActions(
  {
    [AuthActions.GET_USER_DETAILS]: (state = AuthInitialStates, action) => ({
      ...state,
      isLoading: true,
    }),
    [AuthActions.USER_DETAILS_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      user: action.payload.user,
    }),
    [AuthActions.USER_DETAILS_FAILED]: (state = AuthInitialStates, action) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  AuthInitialStates
);
