import React, { useEffect } from "react";
import Header from "../HeaderSection/Header";
import Breadcrumb from "../Blogs/Breadcrumb";
import FooterSection from "../FooterSection/Footer";
import { getToken, removeToken } from "../../common/Token";
import FullPageLoader from "../../common/loader/FullPageLoader";

const User = (props) => {
  useEffect(() => {
    const data = getToken();
    if (data) {
      window.location.href = `${process.env.REACT_APP_FRONTEND_ENDPOINT}/dashboard`;
    } else {
      window.location.href = `${process.env.REACT_APP_FRONTEND_ENDPOINT}/login`;
    }
  }, []);

  return (
    <div className="thankyou-page">
      {/*====== Scroll To Top Area Start ======*/}
      <div id="scrollUp" title="Scroll To Top">
        <i className="fas fa-arrow-up" />
      </div>
      {/*====== Scroll To Top Area End ======*/}
      <FullPageLoader />
    </div>
  );
};

export default User;
