/** @format */

import { createLogic } from "redux-logic";
import {
  cardListFailed,
  cardListSuccess,
  checkoutRedirect,
  PurchaseSubscriptionFailed,
  purchaseSubscriptionSuccess,
  redirectTo,
  SubscriptionActions,
  subscriptionListFailed,
  subscriptionListSuccess,
  subscriptionPlanFailed,
  subscriptionPlanSuccess,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const getSubscriptionLogic = createLogic({
  type: SubscriptionActions.REQUEST_SUBSCRIPTION_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SUBSCRIPTION.service,
      ApiRoutes.GET_SUBSCRIPTION.url,
      ApiRoutes.GET_SUBSCRIPTION.method,
      ApiRoutes.GET_SUBSCRIPTION.authenticate,
      action.payload,
      undefined
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }

      dispatch(subscriptionListFailed());
      done();
    } else {
      dispatch(
        subscriptionListSuccess({
          subscriptionList: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */

const getSubscriptionPlanLogic = createLogic({
  type: SubscriptionActions.SUBSCRIPTION_PLAN_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SUBSCRIPTION_PLAN.service,
      ApiRoutes.GET_SUBSCRIPTION_PLAN.url.replace(":id", action.payload.id),
      ApiRoutes.GET_SUBSCRIPTION_PLAN.method,
      ApiRoutes.GET_SUBSCRIPTION_PLAN.authenticate,
      action.payload,
      undefined
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }

      dispatch(subscriptionPlanFailed());
      done();
    } else {
      dispatch(
        subscriptionPlanSuccess({
          subscriptionPlan: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const getCardsLogic = createLogic({
  type: SubscriptionActions.REQUEST_CARD_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CARDS.service,
      ApiRoutes.GET_CARDS.url,
      ApiRoutes.GET_CARDS.method,
      ApiRoutes.GET_CARDS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }

      dispatch(cardListFailed());
      done();
    } else {
      dispatch(
        cardListSuccess({
          cards: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */

const getPurchaseSubscriptionLogic = createLogic({
  type: SubscriptionActions.REQUEST_PURCHASE_SUBSCRIPTION,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHECKOUT.service,
      ApiRoutes.CHECKOUT.url,
      ApiRoutes.CHECKOUT.method,
      ApiRoutes.CHECKOUT.authenticate,
      undefined,
      action.payload
    );
    if (isError) {
      showError(messages[0]);
      dispatch(PurchaseSubscriptionFailed());
      done();
    } else {
      showSuccess(messages[0]);
      dispatch(purchaseSubscriptionSuccess());
      dispatch(checkoutRedirect({ checkoutData: responseData.transaction }));

      dispatch(redirectTo({ path: "/thankyou" }));
      done();
    }
  },
});

/**
 *
 */

export const SubscriptionLogics = [
  getSubscriptionLogic,
  getSubscriptionPlanLogic,
  getCardsLogic,
  getPurchaseSubscriptionLogic,
];
