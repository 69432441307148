import { createAction } from "redux-actions";

export const AuthActions = {
  GET_USER_DETAILS: "Request to fetch user details",
  USER_DETAILS_SUCCESS: "User details fetched successfully!",
  USER_DETAILS_FAILED: "Failed to fetch User details!",
};

export const getUserDetails = createAction(AuthActions.GET_USER_DETAILS);
export const userDetailsSuccess = createAction(
  AuthActions.USER_DETAILS_SUCCESS
);
export const userDetailsFailed = createAction(AuthActions.USER_DETAILS_FAILED);
