import React, { Component } from "react";

const data = {
  heading: "Find, track and win your next opportunity",
  headingTwo: "Work faster with powerful tools.",
  headingThree: "More productivity with less effort",
  headingText:
    "Use our razor-sharp filters to find your next prime contracting or subcontracting opportunity. Easily build your pipeline with accurate and actionable data on each contract opportunity or potential client. In one click, export lists to your CRM and put your sales team into powerdrive.",
  thumbOne: "/img/discover_thumb.png",
  thumbTwo: "/img/discover_thumb_3.png",
};
const discoverData = [
  {
    id: 1,
    iconClass: "fas fa-check",
    text: "10X your pipeline with real time federal data and GovCon business intelligence.",
  },
  {
    id: 2,
    iconClass: "fas fa-check",
    text: "Get insights into the GovCon industry you can’t find anywhere else.",
  },
  {
    id: 3,
    iconClass: "fas fa-check",
    text: "Spends less time research and more time partnering, closing, and delivering on new opportunities.",
  },
  {
    id: 4,
    iconClass: "fas fa-check",
    text: "Access all your pipeline and prospecting data from one place and replace busy work with high value work.",
  },
];
const discoverIcon = [
  {
    id: 1,
    iconClass: "fas fa-bell",
  },
  {
    id: 2,
    iconClass: "fas fa-envelope-open",
  },
  {
    id: 3,
    iconClass: "fas fa-video",
  },
];

class DiscoverSection extends Component {
  state = {
    data: {},
    discoverData: [],
    discoverIcon: [],
  };
  componentDidMount() {
    this.setState({
      data: data,
      discoverData: discoverData,
      discoverIcon: discoverIcon,
    });
  }

  render() {
    return (
      <section className="section discover-area bg-gray overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              {/* Discover Thumb */}
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                <img src={this.state.data.thumbOne} alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              {/* Discover Text */}
              <div className="discover-text pt-4 pt-lg-0">
                <h2 className="pb-4 pb-sm-0">{this.state.data.heading}</h2>
                <p className="d-none d-sm-block pt-3 pb-4">
                  {this.state.data.headingText}
                </p>
                {/* Check List */}
                <ul className="check-list">
                  {this.state.discoverData.map((item, idx) => {
                    return (
                      <div key={`do_${idx}`}>
                        <li className="py-2">
                          {/* List Box */}
                          <div className="list-box media">
                            <span className="icon align-self-center">
                              <i className={item.iconClass} />
                            </span>
                            <span className="media-body pl-2">{item.text}</span>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                </ul>
                <div className="icon-box d-flex mt-3">
                  {this.state.discoverIcon.map((item, idx) => {
                    return (
                      <div key={`il_${idx}`} className="service-icon pr-3">
                        <span>
                          <i className={item.iconClass} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DiscoverSection;
