/** @format */

export const SubscriptionInitialStates = {
  isLoading: false,
  subscriptionList: {},
  subscriptionPlan: {},
  isCardLoading: false,
  cards: [],

  isSubmitting: false,
  isAdded: false,
  checkoutData: {},
};
