/** @format */
import { createLogic } from "redux-logic";
import { push } from "react-router-redux";
import { SubscriptionLogics } from "./Subscription";
import { AuthLogics } from "./Auth";
import { GlobalSettingLogics } from "./globalSetting";

export const redirectToLogic = createLogic({
  type: "REDIRECT_TO",
  async process(data, dispatch, done) {
    const action = data.action;
    dispatch(push(action.payload.path));
    done();
  },
});

export const AllLogics = [
  ...SubscriptionLogics,
  ...AuthLogics,
  ...GlobalSettingLogics,
  redirectToLogic,
];
