import Cookies from 'universal-cookie';
const cookies = new Cookies();

const domain = process.env.VERCEL_ENV === "preview" ? process.env.VERCEL_BRANCH_URL : process.env.REACT_APP_COOKIE_DOMAIN;

const getToken = () => {
    const token = cookies.get('token', { domain, path: '/' });
    return token ? token : "";
};

const removeToken = () => {
    const token = cookies.remove('token', { domain, path: '/' });
    return "";
};

export {
    getToken,
    removeToken,
};
