import React from "react";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import configureStore from "./redux/store";

// importing MyRouts where we located all of our theme
import MyRouts from "./routers/routes";

const history = createBrowserHistory();
const store = configureStore(history);

function App() {
  return (
    <>
      <Provider store={store}>
        <MyRouts history={history} />
      </Provider>
    </>
  );
}

export default App;
