/** @format */

import { createLogic } from "redux-logic";
import {
  GlobalSettingActions,
  globalSettingListFailed,
  globalSettingListSuccess,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const getGlobalSettingLogic = createLogic({
  type: GlobalSettingActions.REQUEST_GLOBALSETTING_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_GLOBALSETTING.service,
      ApiRoutes.GET_GLOBALSETTING.url,
      ApiRoutes.GET_GLOBALSETTING.method,
      ApiRoutes.GET_GLOBALSETTING.authenticate,
      action.payload,
      undefined
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }

      dispatch(globalSettingListFailed());
      done();
    } else {
      dispatch(
        globalSettingListSuccess({
          globalSettingList: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */

export const GlobalSettingLogics = [
  getGlobalSettingLogic
];
