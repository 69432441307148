import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Header from "../HeaderSection/Header";
import Breadcrumb from "../Blogs/Breadcrumb";
import FooterSection from "../FooterSection/Footer";
import {
  getUserDetails,
  requestCardList,
  requestPurchaseSubscription,
  subscriptionPlanRequest,
} from "../../redux/actions";
import { getValidationErrors } from "../../helpers";
import { CardSchema } from "../../validators";
import { monthOptions, yearOptions } from "../../common";
import Loader from "../../common/loader";
import { getToken } from "../../common/Token";

const CheckoutPage = (props) => {
  const initialStates = {
    cardName: "",
    cardNumber: "",
    expMonth: "",
    expYear: "",
    cvv: "",
    stripeCardId: "newCard",
    type: "newCard",
  };

  let profileInitialStates = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const [inputs, setInputs] = useState(initialStates);
  const [errors, setErrors] = useState(initialStates);

  const [profileInputs, setProfileInputs] = useState(profileInitialStates);

  const [showModal, setShowModal] = useState(false);

  const {
    match: { params },
  } = props || {};

  const {
    subscriptionPlan = {},
    cards = [],
    isSubmitting,
    isLoading,
    isCardLoading,
  } = useSelector((state) => state.subscriptionReducer);
  const { user = {} } = useSelector((state) => state.authReducer);
  const { currentPlan } = user || {};

  useEffect(() => {
    if (getToken()) {
      dispatch(subscriptionPlanRequest({ id: params.id }));
      dispatch(requestCardList());
      dispatch(getUserDetails());
    } else {
      // history.push(`${process.env.REACT_APP_FRONTEND_ENDPOINT}/login`);
      window.location.href = `${process.env.REACT_APP_FRONTEND_ENDPOINT}/login`;
    }
  }, []);

  useEffect(() => {
    setProfileInputs({
      firstName: user ? user.firstName : "",
      lastName: user ? user.lastName : "",
      email: user ? user.email : "",
      mobileNumber: user ? user.mobileNumber : "",
    });
  }, [user]);

  const handleModal = () => {
    setShowModal((prevstate) => !prevstate);
  };
  const handleChange = (e) => {
    const {
      target: { name, value, checked, type },
    } = e;
    console.log("name, value, checked, type", name, value, checked, type);
    if (checked) {
      setInputs({
        ...inputs,
        stripeCardId: value === "newCard" ? "newCard" : value,
        type: value === "newCard" ? "newCard" : "oldCard",
      });
    } else {
      setInputs({
        ...inputs,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (
        user.numberOfFollwedOpportunityUsed >
          subscriptionPlan.numberOfFollwedOpportunity ||
        user.numberOfFollwedVendorsUsed >
          subscriptionPlan.numberOfFollwedVendors ||
        user.numberOfUsersUsed > subscriptionPlan.numberOfUsers
      ) {
        setShowModal(true);

        return;
      }

      const { stripeCardId, type, cvv } = inputs;

      let payload = {
        ...inputs,
        ...profileInputs,
        cvc: cvv,
        stripeCardId: "",
        subscriptionPlanId: params.id,
      };

      let payloadData = {
        ...profileInputs,
        subscriptionPlanId: params.id,
        stripeCardId: stripeCardId,
        type: type,
      };

      if (type === "newCard") {
        CardSchema.validateSync(payload, {
          abortEarly: false,
        });
        dispatch(
          requestPurchaseSubscription({
            ...payload,
          })
        );
      } else {
        dispatch(
          requestPurchaseSubscription({
            ...payloadData,
          })
        );
      }

      console.log("payloadpayloadpayload", payload, payloadData);
    } catch (error) {
      setErrors(getValidationErrors(error));
    }
  };

  console.log(
    "showModal",
    showModal,
    "user",
    user,
    "subscription",
    subscriptionPlan
  );

  const { subscriptionAmount, subscriptionName, validityType } =
    subscriptionPlan || {};

  const {
    cardName = "",
    cardNumber = "",
    cvv = "",
    stripeCardId,
  } = inputs || {};

  return (
    <div className="checkout-page">
      {/*====== Scroll To Top Area Start ======*/}
      <div id="scrollUp" title="Scroll To Top">
        <i className="fas fa-arrow-up" />
      </div>
      {/*====== Scroll To Top Area End ======*/}
      <div className="main">
        <Header imageData={"/img/logo-white.png"} />
        <Breadcrumb title="Checkout" />
        <section id="checkout" className="contact-area ptb_100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-6">
                {/* Section Heading */}
                <div className="section-heading text-center">
                  <h2 className="text-capitalize">Order Summary</h2>
                  <p className="d-none d-sm-block mt-4">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Laborum obcaecati dignissimos quae quo ad iste ipsum
                    officiis deleniti asperiores sit.
                  </p>
                  <p className="d-block d-sm-none mt-4">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Laborum obcaecati.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-12 col-md-8">
                {/* Contact Us */}
                <div className="shadow-lg bg-white p-3 rounded mb-4">
                  <h4 className="mb-2">Payment Details</h4>
                  <p className="mb-4">
                    Complete your purchase by providing your payment Details
                  </p>
                  {isCardLoading ? (
                    <div class="custom-control custom-radio saved-card-wrap">
                      <label class="custom-control-label cursor-pointer d-flex justify-content-between align-items-center">
                        <div className="mx-3 w-100">
                          <div class="font-weight-bold mb-1">
                            <Skeleton className="w-50 " height={16} />
                          </div>

                          <div class="font-weight-medium text-gray">
                            <Skeleton height={12} />
                          </div>
                        </div>
                      </label>
                    </div>
                  ) : cards && cards.length ? (
                    cards.map((item, index) => {
                      return (
                        <div
                          class="custom-control custom-radio saved-card-wrap"
                          key={index}
                        >
                          <input
                            type="radio"
                            class="custom-control-input"
                            id={`radio${index}`}
                            name="stripeCardId"
                            value={item.id}
                            //checked={index === 0 ? true : false}
                            onChange={handleChange}
                          />

                          <label
                            class="custom-control-label cursor-pointer d-flex justify-content-between align-items-center"
                            for={`radio${index}`}
                          >
                            <div className="mx-3">
                              <div class="font-weight-bold mb-1">
                                {item.brand} ************{item.last4}
                                {index === 0 ? (
                                  <span className="status-badge status-primary ml-3 ">
                                    Primary
                                  </span>
                                ) : null}
                              </div>

                              <div class="font-weight-medium text-gray">
                                Card expires on {item.exp_month}/{item.exp_year}
                                .
                              </div>
                            </div>
                            <div>
                              <img
                                src={
                                  item.brand === "MasterCard"
                                    ? "/img/mastercard.svg"
                                    : item.brand === "AmericanExpress"
                                    ? "/img/american-express.svg"
                                    : "/img/visa.svg"
                                }
                                alt=""
                                width="50px"
                              />
                            </div>
                          </label>
                        </div>
                      );
                    })
                  ) : null}

                  <div class="custom-control custom-radio saved-card-wrap">
                    <input
                      type="radio"
                      class="custom-control-input"
                      id="radionew"
                      name="stripeCardId"
                      value="newCard"
                      onChange={handleChange}
                      checked={stripeCardId === "newCard" ? true : false}
                      cj
                    />

                    <label
                      class="custom-control-label cursor-pointer d-flex justify-content-between align-items-center"
                      for="radionew"
                    >
                      <div className="mx-3">
                        <div class="font-weight-bold mb-1">New card</div>
                        <div class="font-weight-medium text-gray">
                          Enter new card details
                        </div>
                      </div>
                    </label>
                    <div className="row mt-4">
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="cardName"
                            placeholder="Name on card*"
                            required="required"
                            onChange={handleChange}
                            value={cardName}
                          />
                          <div className="text-error">
                            {errors && errors.cardName ? errors.cardName : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="cardNumber"
                            placeholder="Card Number*"
                            required="required"
                            onChange={handleChange}
                            value={cardNumber}
                            maxLength="16"
                          />
                          <div className="text-error">
                            {errors && errors.cardNumber
                              ? errors.cardNumber
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group">
                          <select
                            class="form-control"
                            onChange={handleChange}
                            name="expMonth"
                          >
                            <option>Month</option>
                            {monthOptions && monthOptions.length
                              ? monthOptions.map((item, index) => (
                                  <option value={`${item.value}`} key={index}>
                                    {item.label}
                                  </option>
                                ))
                              : []}
                          </select>
                          <div className="text-error">
                            {errors && errors.expMonth ? errors.expMonth : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group">
                          <select
                            class="form-control"
                            onChange={handleChange}
                            name="expYear"
                          >
                            <option>Year</option>
                            {yearOptions && yearOptions.length
                              ? yearOptions.map((item, index) => (
                                  <option value={`${item.value}`} key={index}>
                                    {item.label}
                                  </option>
                                ))
                              : []}
                          </select>
                          <div className="text-error">
                            {errors && errors.expYear ? errors.expYear : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="cvv"
                            placeholder="CVV*"
                            required="required"
                            onChange={handleChange}
                            value={cvv}
                            maxLength="4"
                          />
                          <div className="text-error">
                            {errors && errors.cvv ? errors.cvv : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 pt-4 pt-md-0">
                <div className="summary-card shadow-lg bg-white p-3 rounded">
                  <h4 className="mb-4">Subscription Details</h4>

                  <p className="mb-2 fw-5 d-flex subscription-detail-wrap">
                    <span class=" text-secondary text-label">Plan Name:</span>
                    <span class=" ml-3 text-black text-capitalize">
                      {isLoading ? (
                        <Skeleton width="50px" height={16} />
                      ) : (
                        subscriptionName
                      )}{" "}
                    </span>
                  </p>
                  <div class="separator my-3"></div>
                  <p className="mb-2 fw-5 d-flex subscription-detail-wrap">
                    <span class=" text-secondary text-label">
                      Plan Duration:
                    </span>
                    <span class=" ml-3 text-black text-capitalize">
                      {isLoading ? (
                        <Skeleton width="50px" height={16} />
                      ) : (
                        `1 ${validityType}`
                      )}
                    </span>
                  </p>
                  <div class="separator my-3"></div>
                  <p className="mb-2 fw-5 d-flex subscription-detail-wrap">
                    <span class=" text-secondary  text-label">Amount:</span>
                    <span class=" ml-3 text-black">
                      {isLoading ? (
                        <Skeleton width="50px" height={16} />
                      ) : (
                        `$${subscriptionAmount}`
                      )}
                    </span>
                  </p>

                  {/* <div class="separator my-3"></div>
                  <p className="mb-2 fw-5 d-flex subscription-detail-wrap">
                    <span class=" text-secondary  text-label">Total:</span>
                    <span class=" ml-3 text-black">${subscriptionAmount}</span>
                  </p> */}

                  {currentPlan &&
                  (currentPlan.subscriptionStatus === "Active" ||
                    currentPlan.subscriptionStatus === "Canceled") &&
                  currentPlan.stripePlanId === subscriptionPlan.stripePlanId ? (
                    <button
                      class="btn btn-block mt-4 d-flex align-items-center justify-content-center"
                      disabled={true}
                    >
                      <span>Active Plan</span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      class="btn btn-block mt-4 d-flex align-items-center justify-content-center"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Loader classname={"loader-sm mr-2"} />
                      ) : null}
                      <span>Pay ${subscriptionAmount}</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <FooterSection />

        <div class={`modal ${showModal ? "show" : ""}`} id="exampleModal">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Plan Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Plan Features</th>
                      <th>No of Used</th>
                      <th>New Plan</th>
                      <th>Warning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Followed Opportunity</td>
                      <td>{user.numberOfFollwedOpportunityUsed}</td>
                      <td>{subscriptionPlan.numberOfFollwedOpportunity}</td>
                      <td>
                        {user.numberOfFollwedOpportunityUsed >
                        subscriptionPlan.numberOfFollwedOpportunity
                          ? `Removed ${
                              user.numberOfFollwedOpportunityUsed -
                              subscriptionPlan.numberOfFollwedOpportunity
                            } followed opportunity to purchase this plan`
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Followed Vendor</td>
                      <td>{user.numberOfFollwedVendorsUsed}</td>
                      <td>{subscriptionPlan.numberOfFollwedVendors}</td>
                      <td>
                        {user.numberOfFollwedVendorsUsed >
                        subscriptionPlan.numberOfFollwedVendors
                          ? `Removed ${
                              user.numberOfFollwedVendorsUsed -
                              subscriptionPlan.numberOfFollwedVendors
                            } followed vendor to purchase this plan`
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Number of User</td>
                      <td>{user.numberOfUsersUsed}</td>
                      <td>{subscriptionPlan.numberOfUsers}</td>
                      <td>
                        {user.numberOfUsersUsed > subscriptionPlan.numberOfUsers
                          ? `Removed ${
                              user.numberOfUsersUsed -
                              subscriptionPlan.numberOfUsers
                            } user to purchase this plan`
                          : "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class={`modal-backdrop backdrop ${showModal ? "show" : ""}`}></div>
      </div>
    </div>
  );
};

export default CheckoutPage;
