import React, { Component } from "react";

const initData = {
  preHeading: "Premium",
  preHeadingspan: "Features",
  headingFirst: "Everything",
  heading: "You Get in GovFetch",
  headingText:
    "When you arm your team with realtime government data and business intelligence, there’s always new deals coming through the pipeline.",
  headingTexttwo:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
};

const data = [
  {
    image: "/img/featured_image_1.png",
    title: "Real-Time Data",
    content:
      "Access to XXXX data points that includes real-time contract opportunities, government buyers, key vendor information, and contract awards.",
  },
  {
    image: "/img/featured_image_2.png",
    title: "Business Intelligence",
    content:
      "Key data points on your clients, competition, and teammates in the GovCon industry.",
  },
  {
    image: "/img/featured_image_7.png",
    title: "Actionable Insights",
    content:
      "Access and analyze federal government contracting data to provide your team with insights that drive new business.",
  },
  {
    image: "/img/featured_image_4.png",
    title: "Customizable Reports",
    content:
      "Build customizable reports to target clients, understand spending trends, and more with our all in one reporting system.",
  },
  {
    image: "/img/featured_image_5.png",
    title: "Advance Tracking-",
    content:
      "Monitor contract vehicles, government buyers, competitors, teammates, contract opportunities, vendors, and more with our advanced tracking tool.",
  },
  {
    image: "/img/hand-icon.png",
    title: "Lead Generation",
    content:
      "Generate qualified leads from the government buyers and government contractors all in one place.",
  },
];

class FeatureSection extends Component {
  state = {
    initData: {},
    data: [],
  };
  componentDidMount() {
    this.setState({
      initData: initData,
      data: data,
    });
  }
  render() {
    return (
      <section
        id="features"
        className="section features-area style-two overflow-hidden ptb_100"
      >
        <div className="container">
          <div className="row  justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
              <div class="section-heading text-center">
                {/* <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                  <i className="far fa-lightbulb text-primary mr-1" />
                  <span className="text-primary">
                    {this.state.initData.preHeading}{" "}
                  </span>
                  {this.state.initData.preHeadingspan}
                </span> */}
                <h2 class="text-capitalize">
                  <span class="boder-line-text">{this.state.initData.headingFirst}</span>
                  {this.state.initData.heading}
                </h2>
                {/* <p class="d-none d-sm-block mt-4">
                  {this.state.initData.headingText}
                </p>
                <p class="d-block d-sm-none mt-4">
                  {this.state.initData.headingTexttwo}
                </p> */}
              </div>
            </div>
            {/* <div className="col-12 col-md-10 col-lg-6">
              <div className="section-heading">
                <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                  <i className="far fa-lightbulb text-primary mr-1" />
                  <span className="text-primary">
                    {this.state.initData.preHeading}
                  </span>
                  {this.state.initData.preHeadingspan}
                </span>
                <h2>{this.state.initData.heading}</h2>
                <p className="d-none d-sm-block mt-4">
                  {this.state.initData.headingText}
                </p>
                <p className="d-block d-sm-none mt-4">
                  {this.state.initData.headingTexttwo}
                </p>
              </div>
            </div> */}
          </div>
          <div className="row">
            {this.state.data.map((item, idx) => {
              return (
                <div
                  key={`ffd_${idx}`}
                  className="col-12 col-md-6 col-lg-4 my-3 res-margin"
                >
                  {/* Image Box */}
                  <div className="image-box text-center icon-1 p-5">
                    {/* Featured Image */}
                    <div className="featured-img mb-3">
                      <img className="avatar-sm" src={item.image} alt="" />
                    </div>
                    {/* Icon Text */}
                    <div className="icon-text">
                      <h3 className="mb-2">{item.title}</h3>
                      <p>{item.content}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default FeatureSection;
