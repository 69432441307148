/** @format */

import { createLogic } from "redux-logic";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";
import {
  redirectTo,
  AuthActions,
  userDetailsFailed,
  userDetailsSuccess,
} from "../actions";
import { removeToken } from "../../common/Token";

/**
 *
 */
const getUserLogic = createLogic({
  type: AuthActions.GET_USER_DETAILS,
  async process(data, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined,
      undefined
    );
    if (!isError) {
      dispatch(
        userDetailsSuccess({
          user: responseData.data,
        })
      );
      done();
    } else {
      dispatch(redirectTo({ path: `${process.env.REACT_APP_FRONTEND_ENDPOINT}/login` }));
      removeToken();
      dispatch(userDetailsFailed(messages[0]));
      done();
    }
  },
});

/**
 *
 */

export const AuthLogics = [getUserLogic];
