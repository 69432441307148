import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { requestGlobalSettingList } from "../../redux/actions";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/json-server-1/themeOneFooterSection";

class FooterSection extends Component {
  state = {
    data: {},
    iconList: [],
    footerList_1: [],
    footerList_2: [],
    footerList_3: [],
  };
  componentDidMount() {
    this.props.requestGlobalSettingList();

    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
          iconList: res.data.iconList,
          footerList_1: res.data.footerList_1,
          footerList_2: res.data.footerList_2,
          footerList_3: res.data.footerList_3,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
  }
  render() {
    const {
      globalSettingReducer: { globalSettingList = {} },
    } = this.props;
    console.log(globalSettingList);
    return (
      <div>
        <div className="height-emulator d-none d-lg-block" />
        <footer className="footer-area footer-fixed">
          {/* Footer Top */}
          <div className="footer-top ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Logo */}
                    <a className="navbar-brand" href="/#">
                      <img
                        className="logo"
                        src={"/img/logo/logo-dark.svg"}
                        alt="logo"
                        width="200px"
                      />
                    </a>
                    <p className="mt-2 mb-1">Start your free trial today</p>
                    <p>Try GovFetch free for 7 days. Plans start at $99/month with no contract.</p>
                    <div><a href="/#" class="btn btn-bordered mt-4">Start Free Trial</a></div>
                    {/* Social Icons */}
                    {/* <div className="social-icons d-flex">
                      {this.state.iconList.map((item, idx) => {
                        return (
                          <a href="/#">
                            <i className={item.iconClass} />
                            <i className={item.iconClass} />
                          </a>
                        );
                      })}
                    </div> */}
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_1}
                    </h3>
                    <ul>
                      {this.state.footerList_1.map((item, idx) => {
                        return (
                          <li key={`flo_${idx}`} className="py-2">
                            <a href="/#">{item.text}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_2}
                    </h3>
                    <ul>
                      {this.state.footerList_2.map((item, idx) => {
                        return (
                          <li key={`flt_${idx}`} className="py-2">
                            <a href="/#">{item.text}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">Contacts</h3>
                    <ul>
                      <li className="py-2">
                        <a className="" href="/#">
                          <i class="fas fa-envelope mx-2"></i>
                          {globalSettingList.supportEmail}
                        </a>
                      </li>
                      <li className="py-2">
                        <a className="" href="/#">
                          <i class="fas fa-phone-alt mx-2"></i>
                          {globalSettingList.supportContactPhoneNumber}
                        </a>
                      </li>
                      <li className="py-2">
                        <a className="" href="/#">
                          <i class="fas fa-map-marker-alt mx-2"></i>
                          {globalSettingList.supportAddress}
                        </a>
                      </li>
                    </ul>
                    {/* Store Buttons */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* Copyright Area */}
                  <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                    {/* Copyright Left */}
                    <div className="copyright-left">
                      © Copyrights 2022 GovFetch All rights reserved.
                    </div>
                    {/* Copyright Right */}
                    <div className="copyright-right">
                      {/* Designed By <a href="/#">Chapter247 Infotech</a> */}
                      {/* Social Icons */}
                      <div className="social-icons d-flex">
                        {globalSettingList.facebookStatus === "Active" ? (
                          <a
                            className="bg-primary"
                            href={globalSettingList.facebookURL}
                          >
                            <i className="fab fa-facebook-f text-white" />
                            <i className="fab fa-facebook-f text-white" />
                          </a>
                        ) : null}

                        {globalSettingList.twitterStatus === "Active" ? (
                          <a
                            className="bg-primary"
                            href={globalSettingList.twitterURL}
                          >
                            <i className="fab fa-twitter text-white" />
                            <i className="fab fa-twitter text-white" />
                          </a>
                        ) : null}

                        {globalSettingList.linkedInStatus === "Active" ? (
                          <a
                            className="bg-primary"
                            href={globalSettingList.linkedInURL}
                          >
                            <i className="fab fa-linkedin-in text-white" />
                            <i className="fab fa-linkedin-in text-white" />
                          </a>
                        ) : null}

                        {globalSettingList.youtubeStatus === "Active" ? (
                          <a
                            className="bg-primary"
                            href={globalSettingList.youtubeURL}
                          >
                            <i className="fab fa-youtube text-white" />
                            <i className="fab fa-youtube text-white" />
                          </a>
                        ) : null}

                        {globalSettingList.instaStatus === "Active" ? (
                          <a
                            className="bg-primary"
                            href={globalSettingList.instaURL}
                          >
                            <i className="fab fa-instagram text-white" />
                            <i className="fab fa-instagram text-white" />
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    globalSettingReducer: state.globalSettingReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestGlobalSettingList: () => dispatch(requestGlobalSettingList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterSection);
