import { combineReducers } from "redux";
import { AuthReducer } from "./Auth";
import { SubscriptionReducer } from "./Subscription";
import { GlobalSettingReducer } from "./globalSetting";

export const RootReducer = combineReducers({
  subscriptionReducer: SubscriptionReducer,
  authReducer: AuthReducer,
  globalSettingReducer: GlobalSettingReducer,
});
