/** @format */

import { handleActions } from "redux-actions";
import { SubscriptionActions } from "../actions";
import { SubscriptionInitialStates } from "../states";

export const SubscriptionReducer = handleActions(
  {
    [SubscriptionActions.REQUEST_SUBSCRIPTION_LIST]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [SubscriptionActions.SUBSCRIPTION_LIST_SUCCESS]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      subscriptionList: action.payload.subscriptionList,
    }),
    [SubscriptionActions.SUBSCRIPTION_LIST_FAILURE]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [SubscriptionActions.SUBSCRIPTION_PLAN_REQUEST]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [SubscriptionActions.SUBSCRIPTION_PLAN_SUCCESS]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      subscriptionPlan: action.payload.subscriptionPlan,
    }),
    [SubscriptionActions.SUBSCRIPTION_PLAN_FAILED]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [SubscriptionActions.REQUEST_CARD_LIST]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isCardLoading: true,
    }),
    [SubscriptionActions.CARD_LIST_SUCCESS]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isCardLoading: false,
      cards: action.payload.cards,
    }),
    [SubscriptionActions.CARD_LIST_FAILED]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isCardLoading: false,
      error: true,
    }),

    [SubscriptionActions.REQUEST_PURCHASE_SUBSCRIPTION]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [SubscriptionActions.PURCHASE_SUBSCRIPTION_SUCCESS]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [SubscriptionActions.PURCHASE_SUBSCRIPTION_FAILURE]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [SubscriptionActions.CHECKOUT_REDIRECT]: (
      state = SubscriptionInitialStates,
      action
    ) => ({
      ...state,
      checkoutData: action.payload.checkoutData,
    }),
  },
  SubscriptionInitialStates
);
