/** @format */

import {AppConfig} from "../config";
import {ErrorHandlerHelper} from "./ErrorHandlerHelper";
import {SuccessHandlerHelper} from "./SuccessHandlerHelper";
import Axios from "axios";
import {getToken} from "../common/Token";

/**
 * ApiHelper Class - For making Api Requests
 */
let CancelToken = Axios.CancelToken;
let cancel;
export class ApiHelper {
  _portalGateway;
  _apiVersion;

  constructor() {
    this._portalGateway = AppConfig.API_ENDPOINT;
    this._apiVersion = AppConfig.API_VERSION;
  }
  setHost = (host) => {
    this._portalGateway = host;
  };
  setApiVersion = (version) => {
    this._apiVersion = version;
  };
  /**
   * Fetches from the Gateway defined by the instantiated object. Accepts <T> as output object.
   * @example <caption>"/Auth/UserAccount", "/GetCurrentUser", "GET", "JWT Content"</caption>
   * @param {service} service - wanting to be access ex. "UserAuth/Auth"
   * @param {endpoint} endpoint - you wish to call ex. "/Login"
   * @param {method} method - HTTP Method ex. "GET", "POST", "PUT", "DELETE"
   * @param {authenticated} authenticated - if the request is authenticated (Optional)
   * @param {queryOptions} queryOptions - query options for "GET" method (Optional)
   * @param {body} body - JSON body for "UPDATE, DELETE and POST" methods (Optional)
   */
  async FetchFromServer(
    service,
    endpoint,
    method,
    authenticated = false,
    queryOptions = undefined,
    body = undefined
  ) {
    let url = this._portalGateway + this._apiVersion + service + endpoint;
    let headers = { "Content-Type": "application/json" };
    if (authenticated) {
      headers.Authorization = getToken();
    }

    try {
      method = method.toLowerCase();
      let response = await Axios.request({
        method,
        url,
        data: body,
        headers: headers,
        params: queryOptions,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        }),
        // validateStatus: function (status) {
        //   return status === 200;
        // }
      });

      if (response.ok === false || response.status !== 200) {
        let errorObject = {
          code: response.status,
          data: response.data,
        };

        throw errorObject;
      }
      const data = new SuccessHandlerHelper(response.data);
      return data.data;
    } catch (err) {
      if (Axios.isCancel(err) || !err.response) {
        return {
          isError: true,
          error: "Request cancelled",
          messages: err.message === "cancel" ? [] : ["Request cancelled"],
          data: [],
        };
      } else {
        const errorHelper = new ErrorHandlerHelper(err.response);
        return errorHelper.error;
      }
    }
  }

  /**
   * Cancels the last request.
   */
  cancelRequest = (err) => {
    cancel && cancel(err);
  };
}
