import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { createLogicMiddleware } from 'redux-logic';
import logger from 'redux-logger';

import { RootReducer } from '../reducers';
import { AllLogics } from '../logics';

export default function configureStore(history, initialState) {
  const logicMiddleware = createLogicMiddleware(AllLogics);
  const middlewares = [routerMiddleware(history), logicMiddleware,];
  
  const isProd = process.env.NODE_ENV === 'production';
  if (!isProd) {
    middlewares.push(logger);
  }

  const middleware = applyMiddleware(...middlewares);

  const store = createStore(RootReducer, initialState, middleware);

  return store;
}
