import React, { Component } from "react";

const initData = {
  heading: "Fetch Real-Time Data and Win more Government Contracts",
  content:
    "Direct access to contracts opportunities, contract awards, government, funding data, and competitive business intelligence.",
  btnText: "Start Free Trial",
  formHeading: "Get started for FREE!",
  formText:
    "Fill all fields so we can get some info about you. We'll never send you spam",
  formBtn: "Send Message",
  formBtnText: "By signing up, you accept our",
  formBtnText_2: "Terms",
  formBtnText_3: "Privacy Policy",
};

class HeroSection extends Component {
  state = {
    data: {},
  };
  componentDidMount() {
    this.setState({
      data: initData,
    });
  }
  render() {
    return (
      <section
        id="home"
        className="section welcome-area bg-overlay d-flex align- items-center"
      >
        <div className="container">
          <div className="row justify-content-center mt-5">
            {/* Welcome Intro Start */}
            <div className="col-12 col-lg-6">
              <div className="welcome-intro">
                <h1 className="text-white mt-5">{this.state.data.heading}</h1>
                <p className="text-white mt-4 mb-5">
                  {this.state.data.content}
                </p>
                {/* Store Buttons */}
                <div className="subscribe-form d-flex align-items-center">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="info@yourmail.com"
                  />
                  <button type="submit" className="btn btn-rounded btn-bordered">
                    {this.state.data.btnText}
                  </button>
                </div>

                {/* <div class="input-group my-5 mb-2 mr-sm-2 hero-input">
                  <input
                    type="email"
                    class="form-control hero-email"
                    id="inlineFormInputGroupUsername2"
                    placeholder="Email Address"
                  />
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <a href="/#" className="btn sApp-btn text-uppercase mx-1">
                        {this.state.data.btnText}
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="row mt-5">
                  <div className="col-md-12">
                    <h5 className="text-white mt-2 mb-2">TRUSTED BY EXECUTIVES AT:</h5>
                    <div className="trusted-companies-logo">
                      <ul>
                        <li><a href=""><img src={"/img/google.png"} alt="integration" class="img-fluid" /></a></li>
                        <li><a href=""><img src={"/img/dropbox.png"} alt="integration" class="img-fluid" /></a></li>
                        <li><a href=""><img src={"/img/amazone.png"} alt="integration" class="img-fluid" /></a></li>
                        <li><a href=""><img src={"/img/microsoft.png"} alt="integration" class="img-fluid" /></a></li>
                        <li><a href=""><img src={"/img/salesforce.png"} alt="integration" class="img-fluid" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 d-flex align-items-center">
              {/* Contact Box */}
              <div className="banner-sidebar-block mt-lg-2 mt-md-4">
                <img src="/img/govfetch-img.png" alt="integration" class="img-fluid" />
              </div>
              {/* Contact Form */}
              {/* <div className="contact-box bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
                <form id="contact-form">
                  <div className="contact-top">
                    <h3 className="contact-title">
                      {this.state.data.formHeading}
                    </h3>
                    <h5 className="text-secondary fw-3 py-3">
                      {this.state.data.formText}
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Name"
                          required="required"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email"
                          required="required"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="phone"
                          placeholder="Phone"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-bordered w-100 mt-3 mt-sm-4"
                        type="submit"
                      >
                        {this.state.data.formBtn}
                      </button>
                      <div className="contact-bottom">
                        <span className="d-inline-block mt-3">
                          {this.state.data.formBtnText}{" "}
                          <a href="/#">{this.state.data.formBtnText_2}</a> &amp;{" "}
                          <a href="/#">{this.state.data.formBtnText_3}</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
                <p className="form-message" />
              </div> */}
            </div>
          </div>

        </div>
        {/* Shape Bottom */}
        {/* <div className="shape-bottom">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
          >
            <path
              className="shape-fill"
              fill="#FFFFFF"
              d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
            />
          </svg>
        </div> */}
      </section>
    );
  }
}

export default HeroSection;
