import React from "react";
import "./index.css";

const FullPageLoader = () => {
  return (
    <div className={"main-loader"}>
      <div className={"loader"}>
        <svg className={"circular-loader"} viewBox="25 25 50 50">
          <circle
            className={"loader-path"}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke="#0070bc"
            strokeWidth="2.5"
          />
        </svg>
      </div>
    </div>
  );
};

export default FullPageLoader;
